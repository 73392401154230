import React from 'react'

const Aboutus = () => {
  return (
    <>
      <div className='aboutUsImg d-flex justify-content-center align-items-center'>
        <div className="container">
          <h1 className='aboutUsTitle ms-5'>About Us</h1>
        </div>
      </div>

      <div className="container aboutUsText">
        <div>
          <p>
            Travel Square Holidays is started with the strong aim of providing professional and quality service with best rates for making your holidays always been special & memorable.
            <br /><br />
            We have master staff which are professional and experienced in the field of travelling since a long period of time which aims to give world class travel experiences and services to our clients. Your expectation and satisfaction will be our first priority and will not compromise or disappoint with it. (We will definitely satisfy you by our rates and services)
            <br /><br />
            You just have to share your expectations and destination we will create package with your own guidelines and everything.
            <br /><br />
            One of the best Dubai and Thailand Destination Management Company. Offering your dream destinations in quick hassle free steps. Use our service once and we are sure you would be back for more! Offering holiday packages for Dubai, Thailand, Mauritius, Singapore, Malaysia, Maldives and any other international packages.
          </p>
          <h5>
            Discover new, magnificent and astonishing destinations.
            Make your dreams come true & memorable forever with us.
          </h5>
        </div>
        {/* <a href="/">
          <button className="btn mt-5" style={{fontWeight: 'bold', color: "white", backgroundColor: "#6a00ff"}}>Go To Homepage</button>
        </a> */}
      </div>
    </>
  )
}

export default Aboutus;