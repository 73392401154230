import React from 'react'

const Navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid d-flex justify-content-around align-items-center">
                    <div>
                        <a className="navbar-brand" href="/">
                            <img src="TSH logo.jpg" alt="" width="50" height="50" className="ms-3" />
                            <div className='ms-2 d-inline-block align-text-center navbarTitle'>
                                <strong>Travel Square Holidays</strong>
                            </div>
                        </a>
                    </div>
                    <div className='navContact'>
                        <a className='linkColor' href="tel:+916357163686" rel="noopener noreferrer">
                            <i className="fa-solid fa-phone"></i> +91 63571 63686
                        </a>
                        <a className='linkColor' href="mailto:travelsquareholidays3303@gmail.com" rel="noopener noreferrer" target={"_blank"}>
                            <i className="fa-solid fa-envelope"></i> travelsquareholidays3303@gmail.com
                        </a>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar