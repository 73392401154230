import React from 'react'
import thailand_1 from "../images/thailand_1.jpg";
import thailand_2 from "../images/thailand_2.jpg";
import thailand_3 from "../images/thailand_3.jpg";
import thailand_4 from "../images/thailand_4.jpg";

const PackagesModal = () => {
    return (
        <>
            <div className="modal fade" id="Modal1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: "absolute", right: "10px", top: '10px', backgroundColor:"rgba(200, 200, 200, 0.5)" }} />
                        <div className="modal-header" style={{ overflowX: "scroll", padding: "0" }}>
                            <img src={thailand_1} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt=''/>
                            <img src={thailand_2} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt=''/>
                            <img src={thailand_3} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt=''/>
                            <img src={thailand_4} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt=''/>
                        </div>


                        <div className="modal-body">
                            <div className="container-fluid">
                                <h4>Wonderful Thailand Tour</h4>
                                <h5>5 Day 4 Night</h5>
                                <ul>
                                    <li>Coral island with luch</li>
                                    <li>Alacazar show with transfer</li>
                                    <li>City temple tour in Pattaya Pvt.</li>
                                    <li>1 pcr test at Pattaya</li>
                                    <li>Thailand pass (Complimentary reg.)</li>
                                    <li>All private transfer 3 way</li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="tel:+916357163686" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">Call for More Details <br /> (Price, Booking, Customization)</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackagesModal