import React, { useState } from "react";
import emailjs from 'emailjs-com';
// import 'semantic-ui-css/semantic.min.css';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_f853n0t";
const TEMPLATE_ID = "template_za413d1";
const USER_ID = "2HdbXx2MrllNuzJXe";

const ContactForm = () => {

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };

  return (
    <>
      <div className="container contactComponent">

        <h2 class="h1-responsive font-weight-bold text-center my-4 contactTitle">Contact us</h2>
        <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
          a matter of hours to help you.</p>

        <div className="emailJS">
          <form onSubmit={handleOnSubmit}>
            <Form.Field
              id='form-input-control-last-name'
              className="mb-3"
              control={Input}
              name='user_name'
              placeholder='Name…'
              required
              icon='user circle'
              iconPosition='left'
              />
            <Form.Field
              id='form-input-control-email'
              className="mb-3"
              control={Input}
              name='user_email'
              placeholder='Email…'
              required
              icon='mail'
              iconPosition='left'
              />
            <Form.Field
              id='form-textarea-control-opinion'
              className="mb-3"
              control={TextArea}
              name='user_message'
              placeholder='Message…'
              required
            />
            <Button type='submit' className="btn btn-primary" color='green'>Submit</Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;