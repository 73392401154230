import React from 'react'
import dubai_17 from "../images/dubai_17.jpg";
import dubai_18 from "../images/dubai_18.jpg";
import dubai_19 from "../images/dubai_19.jpg";
import dubai_20 from "../images/dubai_20.jpg";

const PackagesModal = () => {
    return (
        <>
            <div className="modal fade" id="Modal5" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: "absolute", right: "10px", top: '10px', backgroundColor:"rgba(200, 200, 200, 0.5)" }} />
                        <div className="modal-header" style={{ overflowX: "scroll", padding: "0" }}>
                            <img src={dubai_17} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_18} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_19} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_20} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                        </div>


                        <div className="modal-body">
                            <div className="container-fluid">
                                <h4>Citymax Bur Dubai Hotel</h4>
                                <h5>5 Day 4 Night</h5>
                                <ul>
                                    <li>04 Night Accommodation on Above Hotels Daily Buffet Breakfast</li>
                                    <li>Meet & Greet @ Dubai Airport</li>
                                    <li>Return Dubai Airport Transfer</li>
                                    <li>Desert Safari With BBQ Dinner</li>
                                    <li>Half Day Dubai City Tour</li>
                                    <li>Creek Dhow Cruise With BBQ Dinner</li>
                                    <li>Burj Khalifa 124th Floor Abu Dhabi City Tour</li>
                                    <li>All Tours & Transfer On SIC (Seat In Coach) Basis.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="tel:+916357163686" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">Call for More Details <br /> (Price, Booking, Customization)</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackagesModal