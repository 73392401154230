import React from 'react'
import dubai_2 from "../images/dubai_2.jpg";
import dubai_3 from "../images/dubai_3.jpg";
import dubai_4 from "../images/dubai_4.jpg";
import dubai_5 from "../images/dubai_5.jpg";

const PackagesModal2 = () => {
    return (
        <>
            <div className="modal fade" id="Modal2" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: "absolute", right: "10px", top: '10px', backgroundColor:"rgba(200, 200, 200, 0.5)" }} />
                        <div className="modal-header" style={{ overflowX: "scroll", padding: "0" }}>
                            <img src={dubai_2} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_3} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_4} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_5} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                        </div>


                        <div className="modal-body">
                            <div className="container-fluid">
                                <h4>Fortune Atrium / Palm Beach</h4>
                                <h5>5 Day 4 Night</h5>
                                <ul>
                                    <li>4 Night Accommodation in above mentioned hotel on double sharing basis</li>
                                    <li>Daily Buffet Breakfast</li>
                                    <li>Meet & Greet @ Dubai Airport</li>
                                    <li>Return Dubai Airport Transfer - PVT</li>
                                    <li>Desert Safari With BBQ Dinner</li>
                                    <li>Half Day Dubai City Tour</li>
                                    <li>Marina Dhow Cruise With Dinner</li>
                                    <li>Burj Khalifa 124th Floor non prime hours with T & T</li>
                                    <li>VRT PCR Test at hotel</li>
                                    <li>UAE single entry tourist visa with insurance</li>
                                    <li>Dubai vat 5%</li>
                                    <li>All Tours & Transfer On SIC (Seat In Coach) Basis.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="tel:+916357163686" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">Call for More Details <br /> (Price, Booking, Customization)</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackagesModal2