import React from 'react'
import Whyus1 from './images/Whyus1.jpg'
import Whyus2 from './images/Whyus2.jpg'
import Whyus3 from './images/Whyus3.jpg'

const Whyus = () => {
  return (
    <>
      <div className='whyusBg'>
        <h1 className="container text-center pt-5"><strong>Why Us?</strong></h1>

        <div className='container whyus mt-5 pb-5'>
          <div className="card whyusCard my-4">
            <div className="row g-0">
              <div className="col-md-4">
                <img src={Whyus1} className="img-fluid rounded-start" style={{ height: "15em" }} alt="" />
              </div>
              <div className="col-md-8">
                <div className="card-body d-flex flex-column justify-content-evenly" style={{ height: "100%" }}>
                  <h5 className="card-title" id='whyusTitle1'><strong>Customized Packages</strong></h5>
                  <p className="card-text">We can customize travel packages according to your time, budgate, and luxury requirement.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card whyusCard my-4">
            <div className="row g-0 reverseWrap">
              <div className="col-md-8">
                <div className="card-body d-flex flex-column justify-content-evenly" style={{ height: "100%" }}>
                  <h5 className="card-title" id='whyusTitle2'><strong>Value for Money</strong></h5>
                  <p className="card-text">We are sure that you will find our packages value for money.</p>
                </div>
              </div>
              <div className="col-md-4">
                <img src={Whyus2} className="img-fluid rounded-start" style={{ height: "15em" }} alt="" />
              </div>
            </div>
          </div>
          <div className="card whyusCard my-4">
            <div className="row g-0">
              <div className="col-md-4">
                <img src={Whyus3} className="img-fluid rounded-start" style={{ height: "15em" }} alt="" />
              </div>
              <div className="col-md-8">
                <div className="card-body d-flex flex-column justify-content-evenly" style={{ height: "100%" }}>
                  <h5 className="card-title" id='whyusTitle3'><strong>Top Notch Support</strong></h5>
                  <p className="card-text">We are here for you, whenever and wherever you need us while travelling, Just call us and we will sort out anything for you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Whyus