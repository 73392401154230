import React from 'react'
import dubai_6 from '../images/dubai_6.jpg';

const PackagesItems = () => {
    return (
        <div className="col sameHeightPackages" data-bs-toggle="modal" data-bs-target="#Modal3">
            <div className="card bg-dark text-white h-100 cardHover">
                <img src={dubai_6} className="card-img packagesCardItems" alt="..." />
                <div className="card-img-overlay gradientCard">
                    <div className='packagesDetails'>
                        <h5 className="card-title">Orchid Vue / Ascot Hotel</h5>
                        <h6 className="card-text">6 Day 5 Night</h6>
                        <p className="card-text">Click here for more details</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackagesItems