import React from 'react'
import dubai_12 from "../images/dubai_12.jpg";
import dubai_13 from "../images/dubai_13.jpg";
import dubai_14 from "../images/dubai_14.jpg";
import dubai_15 from "../images/dubai_15.jpg";

const PackagesModal = () => {
    return (
        <>
            <div className="modal fade" id="Modal4" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: "absolute", right: "10px", top: '10px', backgroundColor:"rgba(200, 200, 200, 0.5)" }} />
                        <div className="modal-header" style={{ overflowX: "scroll", padding: "0" }}>
                            <img src={dubai_12} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_13} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_14} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_15} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                        </div>


                        <div className="modal-body">
                            <div className="container-fluid">
                                <h4>Omega Hotel / Jacobs Garden Hotel 4 &#x2605;</h4>
                                <h5>6 Day 5 Night</h5>
                                <ul>
                                    <li>05 Night Accommodation as per the mentioned</li>
                                    <li>hotel or similar</li>
                                    <li>Daily Buffet Breakfast</li>
                                    <li>Meet & Greet @ Dubai Airport</li>
                                    <li>Return Dubai Airport Transfer Desert Safari With BBQ Dinner</li>
                                    <li>Half Day Dubai City Tour</li>
                                    <li>Marina Dhow Cruise With 4* Dinner on sic basis Burj Khalifa 124th Floor</li>
                                    <li>UAE single entry tourist visa with insurance All Tours & Transfer On SIC (Seat In Coach) Basis.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="tel:+916357163686" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">Call for More Details <br /> (Price, Booking, Customization)</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackagesModal