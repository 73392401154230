import React from 'react'
import ContactForm from './ContactForm';
import CustomizePackages from './CustomizePackages';
import Landing from './Landing';
import Packeges from './Packeges';
// import Testimonials from './Testimonials';
import Whyus from './Whyus';
import DealOfTheDay from './DealOfTheDay';
// import Instagram from './Instagram';

const Home = () => {
  return (
    <>
      <Landing />
      <DealOfTheDay />
      <Packeges />
      <CustomizePackages />
      {/* <Instagram /> */}
      <Whyus />
      <ContactForm />
      {/* <Testimonials/> */}
    </>
  )
}

export default Home