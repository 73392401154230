import React from 'react';
import './App.css';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Aboutus from './component/Aboutus';
import Footer from './component/Footer';


function App() {
  // const navigateAbout = () => {
  //   if (window.location.pathname === "/aboutus") {
  //     return <Aboutus />
  //   }
  // }
  // const navigateHome = () => {
  //   if (window.location.pathname === "/") {
  //     return <Home />
  //   }
  // }
  return (
    <>
      <Navbar />
      <Home />
      <Aboutus />
      {/* {navigateHome()}
      {navigateAbout()} */}
      <Footer />
    </>
  );
}

export default App;