import React from 'react'
import customizePackage from './images/customizePackage.jpg';

const CustomizePackages = () => {
    return (
        <div className="container my-5">
            <div className="row g-0">
                <div className="col-md-6 py-2 ps-3 customizePackagesInnerComponent1">
                    <div className="card-body d-flex flex-column justify-content-evenly" style={{ height: "100%" }}>
                        <h1 className="card-title" id='whyusTitle1'><strong>Customize Your Dream Package</strong></h1>
                        <p className="card-text">
                            <ul>
                                <li>Family Tours Packages</li>
                                <li>Complete package for your required destination</li>
                                <li>Corporate Tours Packages</li>
                                <li>Airport transfers</li>
                                <li>Group Tours Packages</li>
                                <li>Required sight seeing</li>
                                <li>Couple and Honeymoon Packages</li>
                                <li>SIC and PVT transfers</li>
                                <li>VISA</li>
                            </ul>

                            For any queries and details regarding the packages, please feel free to contact us.
                            Don’t delay anymore,

                            Call-Visit-Book!
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={customizePackage} className="img-fluid customizePackagesInnerComponent2" alt="" />
                </div>
            </div>
        </div>
    )
}

export default CustomizePackages