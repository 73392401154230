import React from 'react'

const Footer = () => {
    return (
        <>
            {/* Footer */}
            <footer className="bg-dark text-white">
                <div className="p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>
                    <div className="row justify-content-around">
                        <div className="col-md-2">
                            <a href="tel:+916357163686" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                                <h4 style={{ color: "#6a00ff" }}><strong>Call Us</strong></h4>
                                <p>+91 63571 63686</p>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="travelsquareholidays3303@gmail.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                                <h4 style={{ color: "#6a00ff" }}><strong>Email</strong></h4>
                                <p>travelsquareholidays3303@gmail.com</p>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a className="nav-link" href="/aboutus">
                                <h4 style={{ color: "#6a00ff" }}><strong>Follow Us</strong></h4>
                                <p className='followIcons'>
                                    <a className='linkColorDisable' href="https://www.instagram.com/travelsquareholidays/" target="_blank">
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                    <a className='linkColorDisable' href="https://www.facebook.com/travelsquareholidays/" target="_blank">
                                        <i className="fa-brands fa-square-facebook"></i>
                                    </a>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>


                {/* Copyright */}
                <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.28)" }}>
                    © 2022 Travel Square Holidays All Rights Reserved
                </div>
                {/* Copyright */}
            </footer>
            {/* Footer */}
        </>
    )
}

export default Footer