import { useState, useEffect } from "react";

const DealOfTheDay = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch('https://api.github.com/repos/viraj-mahida/Travel-Square-Holidays/contents/images', {
      headers: {
        'Accept': 'application/vnd.github.v3+json'
      }
    })
      .then(response => response.json())
      .then(data => {
        const imageFiles = data.filter(file => file.path.endsWith('.png') || file.path.endsWith('.jpg'));
        const imageUrls = imageFiles.map(file => file.download_url);
        setImages(imageUrls);
      });
  }, []);

  return (
    <div className='container packagesCard my-5'>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="image"
          />
        ))}
      </div>
    </div>
  );
};

export default DealOfTheDay;