import React from 'react'
import dubai_7 from "../images/dubai_7.jpg";
import dubai_8 from "../images/dubai_8.jpg";
import dubai_9 from "../images/dubai_9.jpg";
import dubai_10 from "../images/dubai_10.jpg";

const PackagesModal = () => {
    return (
        <>
            <div className="modal fade" id="Modal3" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: "absolute", right: "10px", top: '10px', backgroundColor:"rgba(200, 200, 200, 0.5)" }} />
                        <div className="modal-header" style={{ overflowX: "scroll", padding: "0" }}>
                            <img src={dubai_7} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_8} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_9} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                            <img src={dubai_10} className="modal-title" style={{ height: "17em" }} id="exampleModalLabel" alt='' />
                        </div>


                        <div className="modal-body">
                            <div className="container-fluid">
                                <h4>Orchid Vue / Ascot Hotel</h4>
                                <h5>6 Day 5 Night</h5>
                                <ul>
                                    <li>05 Night Accommodation on Above Hotels Daily Buffet Breakfast</li>
                                    <li>Meet & Greet @ Dubai Airport</li>
                                    <li>Return Dubai Airport Transfer PVT</li>
                                    <li>Desert Safari With BBQ Dinner Half Day Dubai City Tour</li>
                                    <li>Creek Dhow Cruise With BBQ Dinner</li>
                                    <li>Burj Khalifa 124th floor non prime hours with</li>
                                    <li>ticket and transfer</li>
                                    <li>1 day expo access ticket</li>
                                    <li>UAE single entry visa + OTB Dubai vat 5%</li>
                                    <li>All Tours & Transfer On SIC (Seat In Coach) Basis.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="tel:+916357163686" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary">Call for More Details <br /> (Price, Booking, Customization)</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackagesModal