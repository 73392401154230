import React from 'react'

const Landing = () => {
  return (
    <>
      <div className="landing">
        <h1><strong>EXPLORE&nbsp;&nbsp;WORLD&nbsp;&nbsp;WITH</strong></h1>
        <p><strong>World's Top DMC of Dubai and Thailand.</strong></p>
      </div>
    </>
  )
}

export default Landing