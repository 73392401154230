import React from 'react'
import PackagesItems1 from './packagesItems/PackagesItems1'
import PackagesItems2 from './packagesItems/PackagesItems2'
import PackagesItems3 from './packagesItems/PackagesItems3'
import PackagesItems4 from './packagesItems/PackagesItems4'
import PackagesItems5 from './packagesItems/PackagesItems5'
import PackagesItems6 from './packagesItems/PackagesItems6'

import PackagesModal1 from './packagesModal/PackagesModal1';
import PackagesModal2 from './packagesModal/PackagesModal2';
import PackagesModal3 from './packagesModal/PackagesModal3';
import PackagesModal4 from './packagesModal/PackagesModal4';
import PackagesModal5 from './packagesModal/PackagesModal5';
import PackagesModal6 from './packagesModal/PackagesModal6';

const Packeges = () => {
  return (
    <>

      <PackagesModal1 />
      <PackagesModal2 />
      <PackagesModal3 />
      <PackagesModal4 />
      <PackagesModal5 />
      {/* <PackagesModal6 /> */}

      <h1 className="text-center pt-5"><strong>Most Purchased Packages</strong></h1>

      <div className='container packagesCard my-5'>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <PackagesItems1/>
          <PackagesItems2/>
          <PackagesItems3/>
          <PackagesItems4/>
          <PackagesItems5/>
          {/* <PackagesItems6/> */}
        </div>
      </div>
    </>
  )
}

export default Packeges